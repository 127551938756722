import { SearchService } from 'app/services/search.service';
import { Component, Input, OnInit } from '@angular/core';
import { TouchService } from '../../services/touch.service';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    host: {
        class: 'wpdh-search-box-wrapper',
    },
    standalone: false
})
export class SearchBoxComponent implements OnInit {
  isTouch = false;
  @Input() isSmall = false;

  constructor(public touchService: TouchService, private searchService: SearchService) {}

  ngOnInit() {
    this.isTouch = this.touchService.checkTouch();
  }

  toggleSearch() {
    this.searchService.toggleSearch();
  }
}
