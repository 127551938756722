import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pill-country',
    templateUrl: './pill-country.component.html',
    styleUrls: ['./pill-country.component.scss'],
    standalone: false
})
export class PillCountryComponent {
  @Input() icon;
  @Input() type;

  constructor() {}
}
