<div class="workflow-card -card">
  <div [ngClass]="bottomCardPadding ? 'workflow-card -content -bottom-padding' : 'workflow-card -content'" class="workflow-card -content">
    <span class="workflow-card -content-title">
      <span class="-step-name">{{ title }}</span>
      &nbsp;
      <span class="workflow-card -content-subtitle" *ngIf="tasksComplete > -1;else basicTask">
        ({{ tasksComplete }} of {{ taskCount }} complete)
      </span>
      <ng-template #basicTask>
        <span  class="-fontXSmall" >
          {{ taskCount }}
        </span>
      </ng-template>
    </span>
    <!-- <p class="-fontSmall workflow-card" [ngClass]="isApiPage ? '-content-description-width' : '-content-description'">
      {{ description }}
    </p> -->
    
    <div>
      <div class="workflow-card -childrenContainer">
        <ng-content></ng-content>
      </div>
   </div>
   <!-- <link-icon
      iconclass="-sm -colorPrimaryGreen"
      class="workflow-card -link-icon -toggle"
      (click)="changeExpandState()"
      [icon]="_childrenCollapsedState.iconPath">
      {{ _childrenCollapsedState.label }}
    </link-icon> -->
  </div>
</div>