import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'section-collapsable-parent',
    templateUrl: './section-collapsable-parent.component.html',
    styleUrls: ['./section-collapsable-parent.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SectionCollapsableParentComponent {
  @Input() title: string;
  @Input() linktext: string;
  @Input() to: string;
  @Input() primaryBlackBG: false;
  @Input() primaryBlackBG40: false;
  @Input() smallTitle: false;

  constructor() {}
}
