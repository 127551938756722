import { Component } from '@angular/core';

@Component({
    selector: 'secondary-masthead',
    templateUrl: './secondary-masthead.component.html',
    styleUrls: ['./secondary-masthead.component.scss'],
    standalone: false
})
export class SecondaryMastheadComponent {

  constructor() { }

}
