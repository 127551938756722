import { WorkflowIconService } from 'app/services/workflow-icon.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';
import { Router } from '@angular/router';

const animationTime = 175;

@Component({
    selector: 'workflow-setup-card',
    templateUrl: './workflow-setup-card.component.html',
    animations: [
        trigger('_closed', [
            state('false', style({
                height: AUTO_STYLE,
            })),
            state('true', style({
                height: 0,
            })),
            transition('false <=> true', [animate(animationTime)]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WorkflowSetupCardComponent implements OnInit, OnChanges {
  @ViewChild('childrenContainer')
  childrenContainer: ElementRef;

  @Input() status: string;
  @Input() order: string;
  @Input() title: string;
  @Input() taskCount: number;
  @Input() tasksComplete = -1;
  @Input() description: string;
  @Input() nocontent = false;
  @Input() bottomCardPadding = false;
  @Input() expanded: boolean;
  @Input() card: string;

  iconProperties = {
    iconPath: '',
    className: '',
  };

  _childrenCollapsedState = {
    closed: true,
    iconPath: 'dropdown',
    label: 'Expand',
  };

  changeExpandState() {
    this._childrenCollapsedState.closed = !this._childrenCollapsedState.closed;

    if (this._childrenCollapsedState.closed) {
      this._childrenCollapsedState.iconPath = 'dropdown';
      this._childrenCollapsedState.label = 'Expand';
    } else {
      this._childrenCollapsedState.iconPath = 'dropup';
      this._childrenCollapsedState.label = 'Collapse';
    }
  }
  isApiPage = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private workflowIconService: WorkflowIconService,
    private route: Router
  ) {}

  ngOnInit() {
    this.expanded
      ? (this._childrenCollapsedState = {
          closed: false,
          iconPath: 'dropup',
          label: 'Collapse',
        })
      : (this._childrenCollapsedState = {
          closed: true,
          iconPath: 'dropdown',
          label: 'Expand',
        });
    this.iconProperties = this.workflowIconService.changeIcon(this.status, 'card');
    this.isApiPage = this.route.routerState.snapshot.url.includes('/dashboard/go-live') ? false : true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status && !this.isApiPage) {
      this.iconProperties = this.workflowIconService.changeIcon(changes.status.currentValue, 'card');
    }
  }

  // eslint-disable-next-line
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
}
