import { Component, Input } from '@angular/core';
import { dropdownLink } from 'app/models/dropdownLink.interface';

@Component({
    selector: 'header-menu-dropdown',
    templateUrl: './header-menu-dropdown.component.html',
    standalone: false
})
export class HeaderMenuDropdownComponent {
  @Input() dropdownTo: dropdownLink[];
  @Input() type = 'tabs';
  @Input() menuContent = null;

  constructor() {}

}
