import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BreakpointService } from 'app/modules/breakpoint/breakpoint.service';

@Component({
    selector: 'multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    animations: [
        trigger('dropdownMultiselectState', [
            state('inactive', style({ height: '0' })),
            state('active', style({ height: '*' })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-in')),
        ]),
    ],
    standalone: false
})
export class MultiSelectDropdownComponent {
  @Input() buttonTitle: string;
  @Input() dropdownContent: string[];
  @Input() size = 'default';
  @Input() showCounts = false;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownFilterOption') dropdownFilterOption: ElementRef<HTMLElement>;
  optionsList: NodeListOf<HTMLElement>;

  state = 'inactive';
  active = false;
  clicked = false;
  bigScreen = true;
  selectionCount = 0;

  constructor(private breakpointService: BreakpointService) {}

  ngOnInit() {
    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      if (['xs', 'sm', 'md', undefined].indexOf(breakpoint) > -1) {
        this.bigScreen = false;
        this.state = 'active';
      } else {
        this.bigScreen = true;
        this.state = 'inactive';
        this.active = false;
      }
    });
  }

  deselectAllOption() {
    if (this.dropdownFilterOption) {
      this.optionsList = this.dropdownFilterOption.nativeElement.querySelectorAll('input[type="checkbox"]:checked');
      this.optionsList.forEach((elem) => {
        (elem as HTMLInputElement).checked = false;
        this.selectionCount--;
      });
    }
  }

  selectOption(value) {
    let selectedIndex = -1;
    const valueToSelect = value;
    if (this.dropdownFilterOption) {
      this.optionsList = this.dropdownFilterOption.nativeElement.querySelectorAll('input[type="checkbox"]');
    }
    this.optionsList.forEach((elem, index) => {
      if ((elem as HTMLInputElement).value === valueToSelect) {
        if ((elem as HTMLInputElement).checked === false) {
          selectedIndex = index;
        }
      }
    });
    if (selectedIndex > -1) {
      (this.optionsList[selectedIndex] as HTMLInputElement).checked = true;
      this.selectionCount++;
      return true;
    }
  }

  deselectOption(value) {
    let finalIndex = -1;
    const valueToDeselect = value;
    if (this.dropdownFilterOption) {
      this.optionsList = this.dropdownFilterOption.nativeElement.querySelectorAll('input[type="checkbox"]');
    }
    this.optionsList.forEach((elem, index) => {
      if ((elem as HTMLInputElement).value === valueToDeselect) {
        if ((elem as HTMLInputElement).checked === true) {
          finalIndex = index;
        }
      }
    });
    if (finalIndex > -1) {
      (this.optionsList[finalIndex] as HTMLInputElement).checked = false;
      this.selectionCount--;
      return true;
    }
  }
  

  toggleState() {
    if (!this.bigScreen) {
      return;
    }
    if (!this.clicked) {
      this.active = !this.active;
      this.setState();
    } else {
      this.clicked = false;
    }
  }

  setState() {
    this.state = this.active ? 'active' : 'inactive';
  }

  optionClicked(content: string) {
    this.clicked = true;
    this.active = false;
    this.state = 'inactive';
    this.buttonTitle = content;
    this.optionSelected.emit(this.buttonTitle);
  }
  optionChecked(content, ev) {
    const checkedOption = {
      name: content.name,
      checked: ev.target.checked,
    };
    if (ev.target.checked) {
      this.selectionCount++;
    } else {
      this.selectionCount--;
    }
    this.optionSelected.emit(checkedOption);
  }
  clickedOutside(): void {
    if (this.bigScreen) {
      this.active = false;
      this.state = 'inactive';
    }
  }
}
