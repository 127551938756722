import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormService } from 'app/services/form.service';

@Component({
    selector: 'app-resend-code',
    templateUrl: './resend-code.component.html',
    styleUrls: ['./resend-code.component.scss'],
    standalone: false
})
export class ResendCodeComponent {
  reSendCodeProcessing = false;
  @Input() resendCodeEmail = '';
  @Input() resendCodeFormId = '';
  @Output() resendCodeResultEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected formService: FormService) {}

  startResendCode() {
    const reSendCodeNotification = {
      reSendCodeSuccess: false,
      reSendCodeError: false,
    };
    this.reSendCodeProcessing = true;
    const params = {
      email: this.resendCodeEmail,
      formId: this.resendCodeFormId,
    };
    this.resendCodeResultEvent.emit(reSendCodeNotification);

    this.formService.postForm(params).subscribe((response) => {
      this.reSendCodeProcessing = false;
      if (response.message && response.message.search('Error') === -1 && response.message.search('failure') === -1) {
        reSendCodeNotification.reSendCodeSuccess = true;
      } else {
        reSendCodeNotification.reSendCodeError = true;
      }
      this.resendCodeResultEvent.emit(reSendCodeNotification);
    });
  }
}
