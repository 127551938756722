import { Component } from '@angular/core';

@Component({
    selector: 'testimonial-rebranding',
    templateUrl: './testimonial-rebranding.component.html',
    standalone: false
})
export class TestimonialRebrandingComponent {
  constructor() {}
}
