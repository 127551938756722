import {AfterViewInit, Component, ElementRef, Input, ViewChild, AfterViewChecked} from '@angular/core';
import {CodeblockService} from '../../services/codeblock.service';
import {Observable} from 'rxjs';
import {WaypointService} from '../../modules/waypoint/waypoint.service';
import {BreakpointService} from '../../modules/breakpoint/breakpoint.service';
import { CopyService } from 'app/services/copy.service';

const ACTIVE_CLASS = 'is-active';

@Component({
    selector: 'codeblock',
    templateUrl: './code-block.component.html',
    styleUrls: ['./code-block.component.scss'],
    standalone: false
})
export class CodeBlockComponent implements AfterViewInit, AfterViewChecked {
  @Input() colour: string;
  @ViewChild('results', { static : true }) results: ElementRef;
  @ViewChild('nav', { static : true }) nav: ElementRef;
  buffer = 75;
  selectedTab: any;
  selectedLanguage: string;
  tabs: any[] = [];
  fixed = false;
  mobile = false;
  waypointObservable: Observable<boolean>;
  items: any;
  successAnimating = false;

  constructor(
    private codeblockService: CodeblockService,
    private waypointService: WaypointService,
    private breakpointService: BreakpointService,
    private copyService: CopyService
  ) {}

  ngAfterViewInit() {
    try {
      this.items = this.results.nativeElement.querySelectorAll(
        'codeBlockItem',
      );

      for (let i = 0; i < this.items.length; i++) {
        let uidTitle = this.items[i].title;

        for (let x = 0; x < 5; x++) {
          uidTitle += 'abcefghijk1234567890'.charAt(Math.floor(Math.random() * 5))
        }

        const tab = {
          title: this.items[i].title,
          titleCode: uidTitle,
          contents: this.items[i].querySelector('[data-contents]'),
          code: this.items[i].querySelector('[data-code]'),
          raw: this.items[i].querySelector('[data-raw]'),
        };

        this.tabs.push(tab);
      }

      this.selectActiveTab(this.tabs[0].titleCode);

      if (this.tabs.length > 1) {
        this.codeblockService.activeLangauge$.subscribe(language => {
          this.selectActiveTab(language);
        });
      }

      this.breakpointService.getBreakpointObservable().subscribe(breakpoint => {
        this.mobile = ['xs', 'sm'].indexOf(breakpoint) > -1;
      });
    } catch (e) {
      // console.error(e)
    }
  }

  ngAfterViewChecked() {
    if (this.nav && !this.waypointObservable) {
      this.waypointObservable = this.waypointService.getElementObservable(
        this.nav.nativeElement,
        this.buffer,
      );

      this.waypointObservable.subscribe(hit => {
        this.fixed = hit;
      });
    }

    for (let i = 0; i < this.items.length; i++) {
      this.tabs[i].code = this.items[i].querySelector('[data-code]');
      this.tabs[i].raw = this.items[i].querySelector('[data-raw]');
    }
  }

  selectActiveTab(tabName: string) {
    const exists = this.tabs.find(t => t.titleCode === tabName);
    if ( exists ) {
      this.selectedLanguage = tabName;
      this.tabs.forEach(tab => {
        if (this.selectedLanguage === tab.titleCode) {
          tab.contents.classList.add(ACTIVE_CLASS);
          this.selectedTab = tab;
        } else {
          tab.contents.classList.remove(ACTIVE_CLASS);
        }
      });
    }
  }

  onSelect(tab: string) {
    this.codeblockService.setActiveLanguage(tab);
  }

  onCopyClick() {
    if (this.copyService.copyText(this.selectedTab.raw.innerText)) {
      this.displaySuccess();
    }
  }

  displaySuccess() {
    if (!this.successAnimating) {
      this.successAnimating = true;
      setTimeout(() => {
        this.successAnimating = false;
      }, 1500);
    }
  }
}
