import { CognitoService } from './../../services/cognito.service';
import { MenuService } from './../../services/menu.service';
import { Component, OnInit, OnChanges, OnDestroy, Output, EventEmitter, Renderer2, Input } from '@angular/core';
import { AccountService } from 'app/services/account.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointService } from 'app/modules/breakpoint/breakpoint.service';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from 'app/services/localStorage.service';
import { ThemeIconService } from 'app/services/theme-icon.service';

@Component({
    selector: 'app-functional-nav',
    templateUrl: './functional-nav.component.html',
    styleUrls: ['./functional-nav.component.scss'],
    standalone: false
})
export class FunctionalNavComponent implements OnInit, OnChanges, OnDestroy {
  reload = true;
  menuIcons: any;
  accountLink: string;
  showLogin = false;
  fullName: string;
  theme = 'light';
  accountSubscription: Subscription = new Subscription();
  @Output() functionalNavReady = new EventEmitter();
  @Output() accPullDown = new EventEmitter();
  toggleMenuClass = true;
  breakPoint;
  mobileDropdown = '-dropdown';
  helpDropDown = '-help-dropdown';
  profileIcon = '-colorSupportingWhite';
  helpIcon = '-colorSupportingWhite';
  @Input() isToggle;

  constructor(
    public menuService: MenuService,
    private cognitoService: CognitoService,
    private themeIconService: ThemeIconService,
    private accountService: AccountService,
    private breakpointService: BreakpointService,
    private localStorageService: LocalStorageService,
    //router here is used in html
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.theme = this.themeIconService.getDefaultSetting();
    this.themeIconService.themeInfo.subscribe((theme) => {
      this.theme = theme;
    });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md') {
        this.closeMenu();
      }
    });

    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      this.breakPoint = breakpoint;
      if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md') {
        this.mobileDropdown = '-mobile-dropdown-hide';
        this.helpDropDown = '-help-menu-dropdown-hide';
      } else if (this.breakPoint === 'lg') {
        this.mobileDropdown = '-dropdown';
        this.helpDropDown = '-help-dropdown';
        this.helpIcon = '-colorSupportingWhite';
      }
    });

    this.menuService.getMenu('help-icon-menu').subscribe((icons) => {
      this.menuIcons = icons.Items[0].items;
      this.functionalNavReady.emit(icons.Items[0].items);

      if (
        this.cognitoService.getUserInfo() &&
        !this.menuIcons.find((target: { icon: string }) => target.icon === 'person')
      ) {
        this.menuIcons.push({
          icon: 'person',
          path: '',
        });
      } else if (
        !this.cognitoService.getUserInfo() &&
        !this.menuIcons.find((target: { path: string }) => target.path === 'account/login')
      ) {
        this.showLogin = true;
      }
    });

    if (this.cognitoService.getUserInfo()) {
      this.accountSubscription.add(this.accountService.getAccount().subscribe());

      this.accountSubscription.add(
        this.accountService.accountInfo.subscribe((accountInfo) => {
          if (accountInfo.name !== undefined) {
            this.fullName = accountInfo.name + ' ' + accountInfo.family_name;
          }
        })
      );
    }

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md') {
        const { target } = e;
        if (target instanceof HTMLElement) {
          if (!target.classList.contains('no-close')) {
            this.closeMenu();
          }
        }
      }
    });
  }

  switchThemeMode() {
    this.theme = this.themeIconService.switchThemeMode(this.theme);
  }

  ngOnChanges() {
    this.reloading();
    this.toggleMenu();
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe;
    }
  }

  private reloading() {
    setTimeout(() => (this.reload = false));
    setTimeout(() => (this.reload = true));
  }

  toggleMenu(icon?): void {
    if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md') {
      if (this.toggleMenuClass) {
        if (icon === 'person') {
          this.mobileDropdown = '-mobile-dropdown-show';
          this.profileIcon = '-colorAccentLightBlue';
        }
        if (icon === 'help') {
          this.helpDropDown = '-help-menu-dropdown-show';
          this.helpIcon = '-colorAccentLightBlue';
        }
        this.accPullDown.emit(true);
      } else {
        if (icon === 'person') {
          this.mobileDropdown = '-mobile-dropdown-hide';
          this.profileIcon = '-colorSupportingWhite';
        }
        if (icon === 'help') {
          this.helpDropDown = '-help-menu-dropdown-hide';
          this.helpIcon = '-colorSupportingWhite';
        }
        this.accPullDown.emit(false);
      }
      this.toggleMenuClass = !this.toggleMenuClass;
    }
  }

  closeMenu(): void {
    this.helpIcon = '-colorSupportingWhite';
    this.helpDropDown = '-help-menu-dropdown-hide';
    this.mobileDropdown = '-mobile-dropdown-hide';
    this.profileIcon = '-colorSupportingWhite';
    this.toggleMenuClass = true;
  }
}
