import { Component, Input, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AUTO_STYLE,
  // ...
} from '@angular/animations';

@Component({
    selector: 'pill-holder-collapsable',
    templateUrl: './pill-holder-collapsable.component.html',
    styleUrls: ['./pill-holder-collapsable.component.scss'],
    animations: [
        trigger('_closed', [
            state('false', style({
                height: AUTO_STYLE,
            })),
            state('true', style({
                height: '2.5rem',
            })),
            transition('false <=> true', [animate('125ms')]),
        ]),
    ],
    standalone: false
})
export class PillHolderCollapsableComponent implements AfterViewInit {
  @ViewChild('pills') pills: any;
  @Input() widthClass: string;
  pillCount = 0;
  _closed = true;
  _iconPath = 'tagcount';
  _iconDisplay: string;

  changeExpandState() {
    this._closed = !this._closed;

    if (this._closed) {
      this._iconPath = 'tagcount';
      this._iconDisplay = `+${this.pillCount}`;
    } else {
      this._iconPath = 'dropup';
      this._iconDisplay = '';
    }
  }

  constructor(private elRef: ElementRef, private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const pillChildren = this.pills.nativeElement.children;
    const maxWidth = this.pills.nativeElement.offsetWidth;
    let currentWidth = 0;
    for (const x of pillChildren) {
      currentWidth += x.offsetWidth;
      if (maxWidth - 36 > currentWidth && x.tagName === 'PILL') {
        this.pillCount += 1;
      }
    }

    // Check if there are enough pills to warrent a dropdown
    if (this.pillCount < pillChildren.length - 1) {
      this.pillCount = pillChildren.length - this.pillCount - 1;
      this._iconDisplay = `+${this.pillCount}`;
    } else {
      // Not enough pills, remove dropdown option
      pillChildren[pillChildren.length - 1].remove();
    }
  }

  // eslint-disable-next-line
  ngAfterViewChecked() {
    this.changeRef.detectChanges();
  }
}
