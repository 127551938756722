import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss'],
    standalone: false
})
export class PillComponent {
  @Input() canclick = false;
  @Input() checked = false;
  @Input() typeFilter = false;
  @Output() filterRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Input() value;

  toggleCheckedStatus() {
    if (this.canclick) {
      this.checked = !this.checked;
    }
  }

  constructor() {}

  deselectFilter(ev){
    this.filterRemoved.emit(this.value);
  }
}
