import { Component, Input } from '@angular/core';

@Component({
    selector: 'site-logo',
    templateUrl: './site-logo.component.html',
    standalone: false
})
export class SiteLogoComponent {
  @Input() type = 'default';

  constructor() {}
}
