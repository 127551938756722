import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'card-related',
  templateUrl: './card-related.component.html',
  styleUrls: ['./card-related.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class CardRelatedComponent extends CardComponent {}
