import { Component, Input } from '@angular/core';

@Component({
    selector: 'small-icon-tile',
    templateUrl: './small-icon-tile.component.html',
    standalone: false
})
export class SmallIconTileComponent {

  @Input() title: any;
  @Input() icon: any;

  constructor() { }


}
