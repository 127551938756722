import { Component, Input } from '@angular/core';

@Component({
    selector: 'note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    standalone: false
})
export class NoteComponent {
  @Input() type: string;

  constructor() {}
}
