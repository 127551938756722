import { Component, Input } from '@angular/core';

@Component({
    selector: 'small-image-card',
    templateUrl: './small-image-card.component.html',
    styleUrls: ['./small-image-card.component.scss'],
    standalone: false
})
export class SmallImageCardComponent {

  @Input() image;

}
