import { Component, Input } from '@angular/core';

@Component({
    selector: 'grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    standalone: false
})
export class GridComponent {
  @Input() inverse = false;

  constructor() {}

}
