import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { WaypointService } from '../../modules/waypoint/waypoint.service';
import { FragmentService } from '../../services/fragment.service';

const VISIBILITY_THRESHOLD = 100;

@Component({
    selector: 'app-back-to-top',
    templateUrl: './back-to-top.component.html',
    styleUrls: ['./back-to-top.component.scss'],
    animations: [
        trigger('buttonVisibilityState', [
            state('inactive', style({
                opacity: 0,
                transform: 'translateY(90%)',
                display: 'none',
            })),
            state('active', style({
                opacity: 1,
                transform: 'none',
                display: 'block',
            })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-in')),
        ]),
    ],
    standalone: false
})
export class BackToTopComponent implements OnInit {
  public buttonState = 'inactive';
  private windowTop = 0;
  private scrollTimeoutId: any = 0;
  private clickTimeoutId: any = 0;

  constructor(private waypointService: WaypointService, public fragmentService: FragmentService) {}

  ngOnInit() {
    this.waypointService.getScrollObservable().subscribe((pos) => {
      const chatBtn: HTMLElement = document.querySelector('#wo_online_image');
      this.windowTop = pos;
      this.buttonState = this.windowTop > VISIBILITY_THRESHOLD ? 'active' : 'inactive';
      if (this.buttonState === 'inactive') {
        chatBtn.style.setProperty('bottom', '15px');
      } else {
        chatBtn.style.setProperty('bottom', '70px');
      }
    });
  }

  handleClick(e: any) {
    try {
      clearTimeout(this.clickTimeoutId);

      this.clickTimeoutId = setTimeout(() => {
        this.fragmentService.scrollToFragment('top');
      }, 200);

      e.preventDefault();
    } catch (e) {
      // Should still work without animation
    }
  }
}
