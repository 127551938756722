import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'article-image-card',
    templateUrl: './article-image-card.component.html',
    standalone: false
})
export class ArticleImageCardComponent implements OnInit {
  @Input() landscape = false;
  @Input() url;
  @Input() image;
  @Input() type = 'news';
  template = 'external';

  constructor() {}

  ngOnInit() {
    if (this.url && this.url.trim().search(/(http|https):/) !== 0) {
      this.template = 'internal';
    }
  }
}
