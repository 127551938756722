import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'accordionBranded',
    templateUrl: './accordionBranded.component.html',
    animations: [
        trigger('closed', [
            state('false', style({
                height: AUTO_STYLE,
            })),
            state('true', style({
                height: 0,
            })),
            transition('false <=> true', [animate('125ms')]),
        ]),
    ],
    standalone: false
})
export class AccordionBrandedComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() hiddenContent: string;
  @Input() expanded: boolean;
  @Input() subtitle: string;

  closed = true;
  icon: 'singlearrow-right' | 'dropdown' = 'singlearrow-right';

  changeExpandState() {
    this.closed = !this.closed;
    this.icon = this.closed ? 'singlearrow-right' : 'dropdown';
  }

  ngOnInit() {
    if(this.expanded) {
      this.closed = false;
      this.icon = 'dropdown';
    }
  }
}
