import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'social-network-share',
    templateUrl: './social-network-share.component.html',
    standalone: false
})
export class SocialNetworkShareComponent implements OnInit {
  openPallete = false;
  mediaUrls: any = {
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    twitter: 'https://www.twitter.com/intent/tweet?url=',
    email: 'mailto:?body=',
  };
  @Input() sharePlatforms: any;
  currentUrl: any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentUrl = window.location.href;
  }

  togglePallete() {
    this.openPallete = !this.openPallete;
  }

  clickedOutside() {
    this.openPallete = false;
  }

  openExternalLink(mediaType) {
    const mediaUrl = this.mediaUrls[mediaType];
    const shareUrl = mediaUrl + this.currentUrl;
    window.open(shareUrl);
  }
}
