import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'doc-index',
    templateUrl: './doc-index.component.html',
    standalone: false
})
export class DocIndexComponent implements OnInit, OnDestroy {
  lastActiveIndex = 0;

  @Input() title;
  @Input() indexContainer: string;
  indexes = [];
  indexRouteSubscription: Subscription;
  prepareIndexes = () => {
    const indexElements = document.querySelector(this.indexContainer).querySelectorAll('h2');
    this.indexes = [];
    const currentUrl = this.router.url.split('#');
    indexElements.forEach((element: HTMLElement, i) => {
      const index = {
        title: element.textContent,
        path: `${currentUrl[0]}#${element.getAttribute('id')}`,
        active: element.getAttribute('id') === currentUrl[1],
      };
      if (element.getAttribute('id') === currentUrl[1]) {
        this.lastActiveIndex = i;
      }
      this.indexes.push(index);
    });
  };

  constructor(public router: Router) {
    this.indexRouteSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.prepareIndexes();
    });
  }

  ngOnInit(): void {
    // every time component activates it will run following to prepare the indexes on page
    this.prepareIndexes();
  }

  ngOnDestroy(): void {
    this.indexRouteSubscription.unsubscribe();
  }

  setActive(activeIndex: number): void {
    this.indexes[this.lastActiveIndex].active = false;
    this.indexes[activeIndex].active = true;
    this.lastActiveIndex = activeIndex;
  }
}
