import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'cardCapability',
    templateUrl: './card-capability.component.html',
    styleUrls: ['./card-capability.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CardCapabilityComponent extends CardComponent {
  ngOnInit() {
    if (!this.iconsize) {
      this.iconsize = '-lg';
    }

    if (!this.iconcolor) {
      this.iconcolor = '-colorPrimaryGreen';
    }
  }
}
