import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-spec-footer',
    templateUrl: './spec-footer.component.html',
    standalone: false
})
export class SpecFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
