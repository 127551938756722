import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class RelatedArticlesCardComponent extends CardComponent {}
