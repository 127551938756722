import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Globals } from '../../../globals';

@Component({
    selector: 'left-nav',
    templateUrl: './left-nav.component.html',
    standalone: false
})
export class LeftNavComponent {
  @Input() meta;
  @Input() isSecNav;
  @Output() sidebarEvent = new EventEmitter<any>();
  @Input() sidebarShow = false;
  constructor(public globals: Globals) {}

  sidebarEventHandler() {
    this.sidebarShow = !this.sidebarShow;
    this.sidebarEvent.emit(this.sidebarShow);
  }
}
