import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  NgZone,
} from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[clickOutside]',
    standalone: false
})
export class ClickOutsideDirective implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() clickOutsideEnabled = true;
  @Input() attachOutsideOnClick = true;
  @Input() emitOnBlur = true;

  @Output() clickOutside = new EventEmitter<void>();

  outsideClickSubscription: Subscription | undefined;
  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document, private _ngZone: NgZone) {
    this._onWindowBlur = this._onWindowBlur.bind(this);
  }
  ngAfterViewInit(): void {
    this.outsideClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          return !this.isInside(event.target as HTMLElement);
        })
      )
      .subscribe(() => {
        this.clickOutside.emit();
      });
  }

  ngOnDestroy(): void {
    this.outsideClickSubscription?.unsubscribe();
    this._removeWindowBlurListener();
  }

  isInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }

  ngOnInit() {
    this._init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachOutsideOnClick'] || changes['exclude'] || changes['emitOnBlur']) {
      this._init();
    }
  }

  private _init() {
    if (this.emitOnBlur) {
      this._initWindowBlurListener();
    }
  }

  private _onWindowBlur() {
    setTimeout(() => {
      if (!document.hidden) {
        this._emit();
      }
    });
  }

  private _emit() {
    if (!this.clickOutsideEnabled) {
      return;
    }
    this._ngZone.run(() => this.clickOutside.emit());
  }

  private _initWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.addEventListener('blur', this._onWindowBlur);
    });
  }

  private _removeWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.removeEventListener('blur', this._onWindowBlur);
    });
  }
}
