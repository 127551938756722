import { Component, EventEmitter, Input, Output } from '@angular/core';
import { filterColors } from './filters';

@Component({
    selector: 'api-collection',
    templateUrl: './api-collection.component.html',
    standalone: false
})
export class ApiCollectionComponent {
  @Input() apis: any;
  filterColors = filterColors;
  @Input() filtersSummary: any;
  @Output() clickRestartFinder: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  restartFinder() {
    this.clickRestartFinder.emit();
  }
}
