import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { multiCheckboxRequiredValidator } from '../../../directives/multi-checkbox-required.directive';
import { trigger, state, style, animate, transition } from '@angular/animations';

// import { formPayload } from '../../types/form-payload';

@Component({
    selector: 'wpform',
    templateUrl: './wp-form.component.html',
    styleUrls: ['./wp-form.component.scss'],
    animations: [
        trigger('successState', [
            state('inactive', style({
                transform: 'none',
                opacity: 0,
                'z-index': -1,
            })),
            state('active', style({
                transform: 'none',
                opacity: 1,
                'z-index': 3,
            })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-in')),
        ]),
        trigger('failureState', [
            state('inactive', style({
                transform: 'none',
                opacity: 0,
                'z-index': -1,
            })),
            state('active', style({
                transform: 'none',
                opacity: 1,
                'z-index': 3,
            })),
            transition('inactive => active', animate('200ms ease-in')),
            transition('active => inactive', animate('200ms ease-in')),
        ]),
    ],
    standalone: false
})
export class WpFormComponent implements OnInit {
  @Input() id: string;
  @Input() questions: any;
  @Input() submitText: any;
  @Input() submitClasses: any;
  @Input() isProcessing: boolean;
  @Output() clickMethod: EventEmitter<any> = new EventEmitter();
  form: UntypedFormGroup;
  successState = 'inactive';
  failureState = 'inactive';

  constructor(protected formService: FormService) {}

  ngOnInit() {
    this.formService.isProcessing.subscribe((res: boolean) => {
      this.isProcessing = res;
    });
    if (this.id) {
      this.formService.getForm(this.id).subscribe(
        (form) => {
          if (form.Items && form.Items.length > 0) {
            if (form.Items[0].questions) {
              this.questions = form.Items[0].questions;
              this.form = this.toFormGroup(this.questions);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (this.questions) {
      this.form = this.toFormGroup(this.questions);
    }
  }

  toFormGroup(questions) {
    const group: any = {};
    questions.forEach((question) => {
      if (question.type === 'checkbox') {
        const checkboxGroup: any = {};

        question.options.forEach((option) => {
          checkboxGroup[option.value] = new UntypedFormControl(option.checked || false);
        });

        group[question.key] = question.required
          ? new UntypedFormGroup(checkboxGroup || '', multiCheckboxRequiredValidator())
          : new UntypedFormGroup(checkboxGroup || '');
      } else {
        group[question.key] = question.required
          ? new UntypedFormControl(question.value || '', [Validators.required, Validators.pattern(question.pattern)])
          : new UntypedFormControl(question.value || '', [Validators.pattern(new RegExp(question.pattern))]);
      }
    });

    return new UntypedFormGroup(group);
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.form);
    this.clickMethod.emit(this.form);
    // if (this.form.invalid) {
    //   Object.keys(this.form.controls).forEach(key => {
    //     this.form.get(key).markAsDirty();
    //   });
    //   return;
    // }

    // const payload = new formPayload(this.id, this.form.value);

    // this.formService.postForm(payload).subscribe(response => {
    //   console.log(response);
    //   this.form.reset();
    //   this.showSuccess();
    //   setTimeout(() => {
    //     this.closeSuccess();
    //   }, 2000);
    // },
    // error => {
    //   this.showError();
    //   setTimeout(() => {
    //     this.closeError();
    //   }, 2000);
    // });
  }

  showSuccess() {
    this.successState = 'active';
  }

  closeSuccess() {
    this.successState = 'inactive';
  }

  showError() {
    this.failureState = 'active';
  }

  closeError() {
    this.failureState = 'inactive';
  }
}
