import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
    selector: 'api-pagination',
    templateUrl: './api-pagination.component.html',
    standalone: false
})
export class APIPaginationComponent implements OnInit, OnChanges {
  @Input() productListSize: any;
  @Input() resultsPerPage: number;
  @Input() paginationMaxItems: number;
  @Input() pageRefresher: boolean;
  @Output() updatePageSelection = new EventEmitter();

  pager = [];
  activePage = 0;
  pagerOffset: number;
  lowerLimit: number;
  upperLimit: number;
  scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0
      });
    });
  };

  constructor() {}

  ngOnInit(): void {
    this.pagerOffset = Math.floor(this.paginationMaxItems / 2);
    this.lowerLimit = 0;
    this.upperLimit = Math.min(this.paginationMaxItems);
    this.calculateBounds();
    if (this.productListSize) {
      this.buildPager();
    }
  }
  ngOnChanges() {
    this.activePage = 0;
    this.buildPager();
  }

  buildPager() {
    this.pager = [];
    const total = this.productListSize;
    const pages = Math.ceil(total / this.resultsPerPage);

    if (total > this.resultsPerPage) {
      for (let i = 0; i < pages; i++) {
        const start = i * this.resultsPerPage;
        const end = (i + 1) * this.resultsPerPage - 1;
        this.pager.push({
          index: i,
          label: i + 1,
          start: start,
          end: Math.min(end, total - 1),
        });
      }
    }
  }
  updatePage(page) {
    this.activePage = page;
    this.calculateBounds();
    this.updatePageSelection.emit(this.activePage);
    this.scrollToTop();
  }
  calculateBounds() {
    this.upperLimit = Math.max(this.paginationMaxItems, this.activePage + this.pagerOffset + 1);
    this.lowerLimit = Math.min(this.pager.length - this.paginationMaxItems, this.activePage - this.pagerOffset);
  }
  onNextPage() {
    if (this.activePage + 1 !== this.pager.length) {
      this.updatePage(this.activePage + 1);
    }
    this.scrollToTop();
  }

  onPrevPage() {
    if (this.activePage - 1 !== -1) {
      this.updatePage(this.activePage - 1);
    }
    this.scrollToTop();
  }
}
