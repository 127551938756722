import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'card-category-product',
    templateUrl: './card-category-product.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CardCategoryProductComponent extends CardComponent {
  template = 'external';

  @HostBinding('class.-default-category-product-card') defaultCardType = false;
  @HostBinding('class.-title-category-product-card') titleCardType = false;

  ngOnInit() {
    if (this.to && this.to.trim().search(/(http|https):/) !== 0) {
      this.template = 'internal';
    }

    if (this.totype === 'card') {
      this.defaultCardType = true;
    }

    if (this.totype === 'title') {
      this.titleCardType = true;
    }
  }
}
