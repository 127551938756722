import { Component, OnChanges } from '@angular/core';
import { CardComponent } from '../card/card.component';
@Component({
    selector: 'cardBasic',
    templateUrl: './card-basic.component.html',
    styleUrls: ['./card-basic.component.scss'],
    standalone: false
})
export class CardBasicComponent extends CardComponent implements OnChanges {
  animate = {
    'fade-animation': false,
  };

  ngOnChanges() {
    this.animate['fade-animation'] = true;
  }
}
