import { WorkflowIconService } from '../../../services/workflow-icon.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'workflow-task',
    templateUrl: './workflow-task.component.html',
    styleUrls: ['./workflow-task.component.scss'],
    standalone: false
})
export class WorkflowTaskComponent implements OnInit, OnChanges {
  @Input() status = 'inactive';
  @Input() title: string;
  @Input() step: string;
  @Input() description: string;

  iconProperties = {
    iconPath: '',
    className: '',
  };

  constructor(private iconService: WorkflowIconService) {}

  ngOnInit() {
    this.iconProperties = this.iconService.changeIcon(this.status, 'task');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      this.iconProperties = this.iconService.changeIcon(changes.status.currentValue, 'task');
    }
  }
}