import { Component } from '@angular/core';
import { CardComponent } from '../card/card.component';
@Component({
    selector: 'base-card',
    templateUrl: './base-card.component.html',
    standalone: false
})
export class BaseCardComponent extends CardComponent {

}
