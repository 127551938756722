import { Component } from '@angular/core';

@Component({
    selector: 'masthead-secondary',
    templateUrl: './masthead-secondary.component.html',
    styleUrls: ['./masthead-secondary.component.scss'],
    standalone: false
})
export class MastheadSecondaryComponent {

  constructor() { }

}
