<h3>Related articles</h3>
<div>
  <span>
    <app-link to="/apis/credential-management-platform/network-tokens">This is an article question topic?</app-link>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
  </span>
  <span>
    <app-link to="/apis/credential-management-platform/network-tokens">This is an article question topic?</app-link>
    <app-link to="/apis/credential-management-platform/network-tokens">This is an article question topic?</app-link>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
  </span>
  <span>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
    <app-link to="/apis/credential-management-platform/security-tokens">Another question here?</app-link>
  </span>
</div>
