import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'card-product',
    templateUrl: './card-product.component.html',
    styleUrls: ['./card-product.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CardProductComponent extends CardComponent {}
