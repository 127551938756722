import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MastheadCTAComponent } from '../masthead-cta/masthead-cta.component';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';

@Component({
    selector: 'masthead-basic-updated',
    templateUrl: './masthead-basic-updated.component.html',
    standalone: false
})
export class MastheadBasicUpdatedComponent extends MastheadCTAComponent {
  @Input() img: string;

  constructor(changeRef: ChangeDetectorRef, store: Store<any>, notificationService: DocPageNotificationService) {
    super(changeRef, store, notificationService);
  }
}
