import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MetaService } from 'app/services/meta.service';

@Component({
    selector: 'api-versions-convergence',
    templateUrl: './api-versions-convergence.component.html',
    standalone: false
})
export class APIVersionsConvergenceComponent implements OnInit {
  pageRoute: string;
  versions = [];
  showVersions = false;
  mostRecentVersion = true;
  bigScreen = true;

  @Input() sampleVersions;

  constructor(private metaService: MetaService, private router: Router) {}

  ngOnInit() {
    // removed from input pram as it was not getting refreshed on sec nav link click
    this.pageRoute = this.router.url;
    this.setVersions();
  }

  setVersions() {
    this.pageRoute && this.pageRoute.split('/').pop() === 'api-specification'
      ? (this.mostRecentVersion = true)
      : (this.mostRecentVersion = false);
    let route;
    // eslint-disable-next-line guard-for-in
    if (this.metaService.metadata.value.versions) {
      for (const x of this.metaService.metadata.value.versions) {
        if (this.metaService.metadata.value['apimatic-spec']) {
          if (x.toLowerCase() === this.metaService.metadata.value.versions[0]) {
            route = this.metaService.metadata.value['secondary-nav'][1].path;
          } else {
            route = this.metaService.metadata.value['secondary-nav'][1].path + '/' + x.split('.').join('-');
          }
        } else {
          if (x.toLowerCase() === this.metaService.metadata.value.versions[0]) {
            route = '/' + this.pageRoute.match(/.*(\/api-specification)/g)[0];
          } else {
            route = '/' + this.pageRoute.match(/.*(\/api-specification)/g)[0] + '/' + x.split('.').join('-');
          }
        }
        this.versions.push({
          name: x,
          route: route,
        });
      }
    } else {
      this.versions = this.sampleVersions;
    }
    this.versions[0].name = this.versions[0].name + ' Latest';
  }

  clickedOutside(): void {
    if (this.bigScreen) {
      this.showVersions = false;
    }
  }
}
