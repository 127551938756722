<div class="api-collection">
  <div class="-filter-results">
    <div class="-results-info">
      <p>{{apis.length}} Results</p>
      <pill *ngFor="let filter of filtersSummary"
      class="-colorAccentLightBlue" 
      [value]="filter.answerText">
      {{filter.answerText}}
      </pill>
      <span class="-clear-results" (click)="restartFinder()">
        <button-icon iconattributes="-sm15" icon="restart"></button-icon><span>Start Again</span>
      </span>      
    </div>    
    <div class="-results">
      <div class="-results-container">
        <card-api-convergence 
          *ngFor="let product of apis"
          [family]="product.familyName"
          [title]="product.productTitle" 
          [externalTo]="product.external ? product.external : false"
          [to]="product.productTo ? product.productTo : undefined">
          <p>{{product.productDescription}}</p>
        </card-api-convergence>
      </div>
    </div>
  </div>
</div>