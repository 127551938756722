import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'masthead-centered',
    templateUrl: './masthead-centered.component.html',
    styleUrls: ['./masthead-centered.component.scss'],
    standalone: false
})
export class MastheadCenteredComponent implements AfterViewInit {
  @Input() inverse = false;
  @Input() image: string;
  @Input() backgroundimage: string;

  constructor(
    private changeRef: ChangeDetectorRef
  ) {}


  scrollDown() {
    const container = document.getElementsByClassName(
      'masthead-centered -container'
    );

    // if (window.scrollY < container[0].clientHeight * 0.9) {
    const target = window.scrollY + container[0].clientHeight * 0.9;
    window.scrollTo({ top: target, left: 0, behavior: 'smooth' });
    // }
  }

  ngAfterViewInit() {
    this.changeRef.detectChanges();
  }
}
