import { CognitoService } from '../../services/cognito.service';
import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
 
@Component({
    selector: 'notification',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: false
})
export class NotificationsComponent implements OnInit {
  @HostBinding('class.hideBanner') hideBanner = false;
  @Input() title: string;
  @Input() noTitle = false;
  @Input() centerDescription = false;
  @Input() showDismissBtn: boolean;
  @Input() autoDismiss: boolean;
  @Input() authcheck = false;
  @Output() dismissNotification: EventEmitter<boolean> = new EventEmitter();
  hideNotification: boolean;
  dismissTimer = 5000;
  constructor(public cognitoService: CognitoService) {}
 
  ngOnInit() {
    if (this.autoDismiss) {
      setTimeout(() => this.dismiss(), this.dismissTimer);
    }
    if (this.authcheck) {
      if (this.cognitoService.getUserInfo() !== null) {
        this.hideBanner = true;
        return;
      }
    }
  }
 
  dismiss() {
    this.hideNotification = true;
    this.dismissNotification.emit(true);
  }
}