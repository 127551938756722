import { Component, Input } from '@angular/core';

@Component({
    selector: 'icon-tile',
    templateUrl: './icon-tile.component.html',
    styleUrls: ['./icon-tile.component.scss'],
    standalone: false
})
export class IconTileComponent {
  @Input() icon: string;

  constructor() { }

}
