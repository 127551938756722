export const filterColors: any = {
  Payment: '-colorAccentLightRed',
  Boarding: '-colorAccentLightRed',
  Notifications: '-colorAccentLightRed',
  Reporting: '-colorAccentLightRed',
  'Security & Fraud': '-colorAccentLightRed',
  Equipment: '-colorAccentLightPurple',
  'Physical/Card Present': '-colorAccentLightPurple',
  'Online/Card Not Present': '-colorAccentLightPurple',
  Mobile: '-colorAccentLightPurple',
  All: '-colorAccentLightPurple',
  US: '-colorAccentLightBlue',
  Mexico: '-colorAccentLightBlue',
  Canada: '-colorAccentLightBlue',
  Europe: '-colorAccentLightBlue',
  Other: '-colorAccentLightBlue',
  Retail: '-colorAccentTeal',
  Restaurant: '-colorAccentTeal',
  MOTO: '-colorAccentTeal',
  eComm: '-colorAccentTeal',
  Fuel: '-colorAccentTeal',
  Lodging: '-colorAccentTeal',
  Healthcare: '-colorAccentTeal',
  'Auto Rental': '-colorAccentTeal',
};
