import { Component } from '@angular/core';
import { CardComponent } from '../card/card.component';
@Component({
  selector: 'reference-card',
  templateUrl: './reference-card.component.html',
  standalone: false
})
export class ReferenceCardComponent extends CardComponent {
  template = 'external';

  ngOnInit() {
    if (this.to && this.to.trim().search(/(http|https):/) !== 0) {
      this.template = 'internal';
    }
  }
}
