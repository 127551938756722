import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'accordion-convergence',
    templateUrl: './accordion-convergence.component.html',
    animations: [
        trigger('closed', [
            state('false', style({
                height: AUTO_STYLE,
            })),
            state('true', style({
                height: 0,
            })),
            transition('false <=> true', [animate('125ms')]),
        ]),
    ],
    standalone: false
})
export class AccordionConvergenceComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() hiddenContent: string;
  @Input() expanded: boolean;
  @Input() subtitle: string;

  closed = true;
  icon: 'plus' | 'subtract' = 'plus';

  changeExpandState() {
    this.closed = !this.closed;
    this.icon = this.closed ? 'plus' : 'subtract';
  }

  ngOnInit() {
    if(this.expanded) {
      this.closed = false;
      this.icon = 'subtract';
    }
  }
}
