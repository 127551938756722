import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss'],
    standalone: false
})
export class WorkflowComponent {
  @Input() title: string;
  @Input() taskStatus = {};
  @Input() taskChange: any;
  @Output() taskChangeChange = new EventEmitter<any>();

  constructor() {}

  updateTaskStatus(task: string, newStatus: string, subtask?: string, index?: number) {
    if (this.taskStatus[task]) {
      if (subtask) {
        if (index >= 0) {
          this.taskStatus[task].subTasks[index].status = newStatus;
          this.taskStatus[task].subTasks[index].subTaskActivity = true;
        } else {
          this.taskStatus[task].subtask.status = newStatus;
        }
      } else {
        this.taskStatus[task].status = newStatus;
      }
    }
    this.taskChange.emit(this.taskStatus);
  }

  updateTaskCount(task: string) {
    if (this.taskStatus[task] && this.taskStatus[task].complete < this.taskStatus[task].total) {
      this.taskStatus[task].complete++;
      if (this.taskStatus[task].complete == this.taskStatus[task].total) {
        this.updateTaskStatus(task, 'checkmark');
      } else {
        this.updateTaskStatus(task, 'active');
      }
    }
  }
}