import { CognitoService } from '../../../services/cognito.service';
import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { LinkComponent } from '../link/link.component';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from 'app/services/page.service';

@Component({
    selector: 'link-icon',
    templateUrl: './link-icon.component.html',
    styleUrls: ['./link-icon.component.scss'],
    standalone: false
})
export class LinkIconComponent extends LinkComponent implements AfterViewInit {
  @Input() icon: string;
  @Input() linkclass: string;
  @Input() iconclass: string;
  @Input() mode = null;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public cognitoService: CognitoService,
    private pageService: PageService
  ) {
    super(router, route, cognitoService);

    if (!this.icon) {
      this.icon = 'thin-arrow-right';
    }
  }

  @HostListener('click', ['$event.target'])
  clickHandler() {
    if (this.mode && this.mode === 'getapikey' && this.to && !this.cognitoService.getUserInfo()) {
      this.pageService.getApiKeyConfirmEvent.emit();
      return;
    }
  }

  ngAfterViewInit(): void {
    if (this.mode && this.mode === 'getapikey' && this.to && !this.cognitoService.getUserInfo()) {
      this.template = 'noRoute';
    }
  }
}
