import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { BreakpointService } from '../../modules/breakpoint/breakpoint.service';
import { Store, select } from '@ngrx/store';
import { toggle } from '../../store/reducer/toggle';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AppState } from '../../types/app-state';
import { Toggle } from '../../types/toggle';
import { tap } from 'rxjs/operators';
import { MetaService } from 'app/services/meta.service';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from 'app/services/localStorage.service';
import { ThemeIconService } from 'app/services/theme-icon.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  isOpen: boolean;
  toggles$: Observable<any[]>;
  accPullDownOpened = false;
  toggleAccountMenu = 0;
  transparentBackground = false;
  metadata = null;
  private eventSub: Subscription;
  mode: string;
  mainMenu = { left: { title: 'Find your solution', path: '/solution-finder' }, right: null };
  functionalMenu: any;
  menuContent = null;
  menusState = false;
  smallMenuState = false;
  menusContentState = false;
  menusNavigationState = false;
  pageBody: HTMLElement;
  pageFooter: HTMLElement;
  menusElement: HTMLElement;
  menuDetailElement: HTMLElement;
  menuHolderElement: HTMLElement;
  menuBackNavigation: HTMLElement;
  theme = 'light';
  @ViewChild('smallScreenMenuHolder') smallScreenMenuHolder: ElementRef<HTMLElement>;

  constructor(
    private metaService: MetaService,
    private breakpointService: BreakpointService,
    protected store: Store<AppState>,
    private eleRef: ElementRef<HTMLElement>,
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private themeIconService: ThemeIconService
  ) {
    this.toggles$ = this.store.pipe(select('toggle'));
  }

  ngAfterViewInit(): void {
    this.menuHolderElement = this.smallScreenMenuHolder.nativeElement.querySelector('.-menu-holder');
    this.menusElement = this.menuHolderElement.querySelector('.-menus');
    this.menuDetailElement = this.menuHolderElement.querySelector('.-menu-content');
    this.menuBackNavigation = this.menuHolderElement.querySelector('.-menu-back');
  }

  ngOnInit() {
    this.theme = this.themeIconService.getDefaultSetting();
    this.themeIconService.themeInfo.subscribe((theme) => {
      this.theme = theme;
    });

    this.eventSub = fromEvent(window, 'scroll')
      .pipe(tap(() => this.scrollHandler()))
      .subscribe();

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: any) => {
      if (event.url !== this.router.url) {
        this.smallMenuState = false;
        this.menusState = false;
        this.menusContentState = false;
        this.menusNavigationState = false;
        this.showBody();
      }
    });

    this.toggles$.subscribe((toggles: Toggle[]) => {
      if (toggles) {
        const navToggle = toggles.filter((toggle) => toggle.name === 'nav');

        if (navToggle && navToggle.length) {
          this.isOpen = navToggle.pop().active;
        }
      }
    });

    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      if (['xs', 'sm'].indexOf(breakpoint) === -1) {
        this.store.dispatch(toggle('nav', false));
      }

      if (['xs', 'sm', 'md'].indexOf(breakpoint) === -1) {
        this.smallMenuState = false;
        this.showBody();
      }
    });

    this.metaService.getMode().subscribe((mode) => {
      this.transparentBackground = mode == 'gradient';
      this.mode = mode;
    });

    this.metaService.metadata.subscribe((meta) => {
      this.metadata = meta;
      if (meta && meta.template && meta.template === 'homepage') {
        this.eleRef.nativeElement.classList.add('-secondaryNav');
      } else {
        this.eleRef.nativeElement.classList.remove('-secondaryNav');
      }
    });
  }

  ngOnDestroy() {
    this.eventSub.unsubscribe();
  }

  switchThemeMode() {
    this.theme = this.themeIconService.switchThemeMode(this.theme);
  }

  scrollHandler() {
    if (window.scrollY > 0) {
      this.transparentBackground = false;
    } else if (this.mode == 'gradient') {
      this.transparentBackground = true;
    }
  }

  hideBody(): void {
    if (this.pageBody && this.pageFooter) {
      this.pageBody.style.cssText = 'display: none;';
      this.pageFooter.style.cssText = 'display: none;';
    }
  }

  showBody(): void {
    if (this.pageBody && this.pageFooter) {
      this.pageBody.style.cssText = 'display: revert;';
      this.pageFooter.style.cssText = 'display: revert;';
    }
  }

  mobileToggleClick(): void {
    this.store.dispatch(toggle('nav'));
    this.pageBody = document.querySelector('app-page');
    this.pageFooter = document.querySelector('app-footer');
    this.smallMenuState = !this.smallMenuState;

    if (this.smallMenuState) {
      this.hideBody();
      this.menusState = true;
      this.toggleAccountMenu = this.accPullDownOpened ? ++this.toggleAccountMenu : this.toggleAccountMenu;
    } else {
      this.showBody();
      this.menusState = false;
      this.menusContentState = false;
      this.menusNavigationState = false;
    }
  }

  prepareSmallMenu(menu): void {
    const mainMenu = menu.slice();
    const docsMenu = mainMenu.splice(1, 2);
    const resultMenu = [...mainMenu, ...docsMenu];
    this.mainMenu.right = resultMenu;
  }

  prepareSmallFunctionalNav(functionalMenu): void {
    this.functionalMenu = functionalMenu.slice(0, 1);
  }

  accountPullDownHandler(isOpened) {
    this.accPullDownOpened = isOpened;
    if (this.smallMenuState && this.accPullDownOpened) {
      this.mobileToggleClick();
    }
  }

  navigateMainMenu(): void {
    this.menuContent = null;
    this.menusState = true;
    this.menusContentState = false;
    this.menusNavigationState = false;
  }

  showMenuContent(mainMenuIndex: number, childMenuIndex: number) {
    this.menuContent = null;
    this.menuContent = this.mainMenu.right[mainMenuIndex].children[childMenuIndex];

    this.menusState = false;
    this.menusContentState = true;
    this.menusNavigationState = true;
  }
}
