<div
  [formGroup]="form"
  class="form -question"
  [class.is-invalid]="!isValid && (isDirty || isTouched)"
  [class.is-valid]="isValid && (isDirty || isTouched)">
  <label [ngClass]="question.required ? '-asterisk' : ''">
    <span class="-fontMedium" [ngClass]="question.disabled ? '-fontMedium -disabled-label' : '-fontMedium'">
      {{ question.label }}
      <!-- <span *ngIf="!question.required">
        (Optional)
      </span> -->
    </span>
    <span class="-fontMedium" *ngIf="question.help" class="form -help">{{ question.help }}</span>

    <ng-container [ngSwitch]="question.type">
      <!-- <input *ngSwitchCase="'text'" [type]="question.type" [formControlName]="question.key" [id]="question.key" (mouseenter)="showTooltip($event)" (mouseleave)="clearTooltip()"/> -->
      <input
        *ngSwitchCase="'text'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [type]="question.type"
        [formControlName]="question.key"
        [name]="question.key"
        [id]="question.key"
        [required]="question.required"
        [placeholder]="question.placeholder ? question.placeholder : ''"
        [ngClass]="{'error': !isValid && (isDirty || isTouched) }"
        [minlength]=question.minlength
        [maxlength]=question.maxlength />

      <input
        *ngSwitchCase="'password'" 
        type="password"
        [autocomplete]="question.autocomplete ? question.autocomplete : 'off'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [formControlName]="question.key"
        [name]="question.key"
        [id]="question.key"
        [ngClass]="{'error': !isValid && (isDirty || isTouched) }"
        [required]="question.required"
        [placeholder]="question.placeholder || ''" />

      <input
        *ngSwitchCase="'date'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [type]="question.type"
        [formControlName]="question.key"
        [name]="question.key"
        [id]="question.key"
        [required]="question.required" />

      <input
        *ngSwitchCase="'email'"
        [placeholder]="question.placeholder"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [type]="question.type"
        [formControlName]="question.key"
        [name]="question.key"
        [id]="question.key"
        [ngClass]="{'error': !isValid && (isDirty || isTouched) }"
        [required]="question.required"
        [maxlength]=question.maxlength />

      <input
        *ngSwitchCase="'number'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [type]="question.type"
        [formControlName]="question.key"
        [name]="question.key"
        [id]="question.key"
        [required]="question.required" />

      <input
        *ngSwitchCase="'tel'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [type]="question.type"
        [formControlName]="question.key"
        [id]="question.key"
        [name]="question.key"
        [required]="question.required" />

      <textarea
        *ngSwitchCase="'textarea'"
        [readonly]="question.disabled ? true : false"
        [class.-disabled]="question.disabled"
        [id]="question.key"
        [name]="question.key"
        [placeholder]="question.placeholder ? question.placeholder : ''"
        [ngClass]="{'error': !isValid && (isDirty || isTouched) }"
        [formControlName]="question.key"
        [minlength]=question.minlength
        [maxlength]=question.maxlength
        [required]="question.required">
      </textarea>

      <select *ngSwitchCase="'select'" [formControlName]="question.key">
        <option value="">-- Please Choose --</option>
        <option *ngFor="let option of question.options" [value]="option.value">{{ option.label }}</option>
      </select>
    </ng-container>

    <!-- <tooltip
      *ngIf="!_tooltipClosed && question.help"
      [location]="_tooltipLocation"
      class="-colorAccentDarkTeal">
      {{ question.help }}
    </tooltip> -->

    <!-- <tooltip
    *ngIf="!_tooltipClosed && !isValid && (isDirty || isTouched)"
    [location]="_tooltipLocation"
    [svgChar]="_svgChar"
    class="-colorAccentDarkTeal">
    {{ question.label }} is required 
  </tooltip> -->

    <span class="form -error" *ngIf="!isValid && (isDirty || isTouched)">
      <span *ngIf="question.customErrorMessage">
        {{ question.customErrorMessage }}
      </span>
      <span *ngIf="!question.customErrorMessage && (errors.required || errors['whitespace']) && question.type !== 'email'">
        Please fill in this field. {{ question.label }} is required
      </span>

      <span *ngIf="!question.customErrorMessage && (errors['invalidname']) && ['name', 'family_name', 'first_name', 'last_name', 'title', 'company'].includes(question.key)">
        {{ question.label }} has invalid special characters.
      </span>

      <span *ngIf="!question.customErrorMessage && errors && question.type === 'email'">
        Please fill in a valid company email address. E.g. name&#64;business.com
      </span>

      <span *ngIf="!question.customErrorMessage && errors && question.key === 'phone'">
        Please fill in a valid phone number. E.g. +19997778888
      </span>

      <span *ngIf="!question.customErrorMessage && errors && question.key === 'Description_Hidden__c'">
        {{ question.label }} has invalid special characters.
      </span>
      <!-- <span *ngIf="!question.customErrorMessage && errors.pattern && question.type !== 'password'">
        Please enter the expected input.
      </span> -->
    </span>
  </label>
</div>