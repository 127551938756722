import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'tab-item',
    templateUrl: './tab-block-item.component.html',
    styleUrls: ['./tab-block-item.component.scss'],
    standalone: false
})
export class TabBlockItemComponent {
  @Input() title: string;
  @Input() test : string;
  @ViewChild('content') content: ElementRef;

  constructor() { }

}
