import { Component, ViewEncapsulation } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
    selector: 'card-product-small',
    templateUrl: './card-product-small.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CardProductSmallComponent extends CardComponent {}
